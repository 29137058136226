<template>
  <div class="service">
    <query-form
      class="content_block"
      label-width="116px"
      size="small"
      label-position="right"
      @search="getList('init')"
      @reset="handleReset"
      :keepOpenedRow="2"
    >
      <el-form-item label="售后状态：" :span="24">
        <check-tag v-model="advancedForm.state" @change="() => getList('init')">
          <check-tag-item
            :value="i.value"
            :key="i.value"
            v-for="i in executionStatusGroup"
            :label="`${i.label}(${i.count || 0})`"
          ></check-tag-item>
        </check-tag>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input
          v-model.trim="advancedForm.orderCode"
          clearable
          placeholder="请输入订单编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户抬头：">
        <el-input
          v-model.trim="advancedForm.unit"
          clearable
          placeholder="请输入客户抬头"
        ></el-input>
      </el-form-item>
      <el-form-item label="售后单号：">
        <el-input
          v-model.trim="advancedForm.afterCode"
          clearable
          placeholder="请输入客户抬头"
        ></el-input>
      </el-form-item>
      <el-form-item label="售后类型：">
        <el-select
          v-model.trim="advancedForm.type"
          clearable
          placeholder="请选择"
          @change="getList('init')"
        >
          <el-option
            v-for="item in typeStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="退货状态：">
        <el-select
          v-model.trim="advancedForm.returngoodsStates"
          clearable
          placeholder="请选择"
          @change="getList('init')"
        >
          <el-option
            v-for="(item, index) in returnStatusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="退款状态：">
        <el-select
          v-model.trim="advancedForm.refundStates"
          clearable
          placeholder="请选择"
          @change="getList('init')"
        >
          <el-option
            v-for="item in refundStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请日期(起)：">
        <el-date-picker
          v-model="advancedForm.createStartTime"
          type="date"
          value-format="yyyy-MM-dd 00:00:00"
          placeholder="请选择申请日期(起)"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="申请日期(止)：">
        <el-date-picker
          v-model="advancedForm.createEndTime"
          type="date"
          value-format="yyyy-MM-dd 23:59:59"
          placeholder="请选择申请日期(止)"
        >
        </el-date-picker>
      </el-form-item>
    </query-form>
    <TableWithPagination
      class="content_block"
      :columnList="columnList"
      :tableData="dataList"
      :pagination.sync="pagination"
      @size-change="getList"
      @current-change="getList"
      @sort-change="getList"
    />
  </div>
</template>
<script>
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import { queryPage, queryStateInfoBySeller } from '@/api/service';
import setTableCols from './configs';
import {
  executionStatusGroup,
  refundStatusOptions,
  returnStatusOptions,
  typeStatusOptions,
} from '@/model/service';

export default {
  name: 'service',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  watch: {
    applyDate (val) {
      if (val) {
        this.advancedForm.createStartTime = val[0];
        this.advancedForm.signEndDate = val[1];
      } else {
        this.advancedForm.createStartTime = undefined;
        this.advancedForm.createEndTime = undefined;
      }
    },
  },
  data () {
    return {
      columnList: setTableCols.call(this),
      dataList: [],
      advancedForm: {
        state: '',
      },
      applyDate: [],
      executionStatusGroup,
      refundStatusOptions,
      returnStatusOptions,
      typeStatusOptions,
    };
  },
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);

    if (this.$route.query.status || this.$route.query.status === 0) {
      this.advancedForm.state = this.$route.query.status + '';
    }

    this.getList();
    this.getSaleContractCount();
  },
  methods: {
    getList (type) {
      if (type === 'init') {
        this.pagination = {
          total: 0,
          currentPage: 1,
          pageSize: 10,
        };
      }

      let { pageSize, currentPage: pageNum } = this.pagination;
      let orderBy = [
        {
          orderByProperty: 'lastUpdateTime',
          orderByType: 'DESC',
        },
      ];

      queryPage({ ...this.advancedForm, orderBy, pageSize, pageNum }).then(
        ({ body }) => {
          this.dataList = body.list;
          this.pagination.total = body.total;
        },
      );
    },
    getSaleContractCount () {
      queryStateInfoBySeller().then(({ body }) => {
        for (let v of this.executionStatusGroup) {
          v.count = body[v.en];
        }
      });
    },
    handleReset () {
      for (let key in this.advancedForm) {
        this.advancedForm[key] = '';
      }

      this.pagination = {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };

      this.getList();
    },
    operate (type, row) {
      let path = '/service/detail';

      if (type !== 'detail') {
        path = '/service/review';
      }

      this.$router.push({
        path,
        query: {
          id: row.id,
          returnType: row.type,
          type,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.service {
  /deep/ .contract-option {
    color: #02a7f0;
    cursor: pointer;
    padding: 0 0.25em;
  }

  .el-range-editor--small.el-input__inner {
    width: 100%;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
