import { setServiceTable } from './serviceConfig';
import { setServiceDetailTable } from './serviceDetailConfig';

export default function setTableCols (type = 'service') {
  const configs = {
    'service': setServiceTable.call(this),
    'serviceDetail': setServiceDetailTable.call(this),
  };

  configs[type].forEach(v => {
    if (!v.minWidth) {
      let title = v.label || v.title;

      v.minWidth = `${title.length * 16 + (v.sortable ? 30 : 20)}px`;
    }

    v.tooltip = true;
  });

  return configs[type];
}
