import {
  executionStatusGroup,
  refundStatusOptions,
  returnStatusOptions,
  typeStatusOptions,
} from '@/model/service';

function convertToName (enums, value) {
  let label = '--';

  for (let v of enums) {
    if (Number(v.value) === Number(value)) {
      label = v.label;
      break;
    }
  }

  return label;
}

export function setServiceTable () {
  const serviceTableCols = [
    {
      label: '售后单号',
      prop: 'afterCode',
    },
    {
      label: '售后类型',
      prop: 'type',
      render: (h, { row }) => {
        return h('span', convertToName(typeStatusOptions, row.type));
      },
    },
    {
      label: '售后状态',
      prop: 'state',
      render: (h, { row }) => {
        return h('span', convertToName(executionStatusGroup, row.state));
      },
    },
    {
      label: '退款状态',
      prop: 'refundStates',
      render: (h, { row }) => {
        return h('span', convertToName(refundStatusOptions, row.refundStates));
      },
    },
    {
      label: '退货状态',
      prop: 'returnGoodsStates',
      render: (h, { row }) => {
        return h('span', convertToName(returnStatusOptions, row.returnGoodsStates));
      },
    },
    {
      label: '关联金额',
      prop: 'rnAmount',
    },
    {
      label: '订单编号',
      prop: 'orderCode',
    },
    {
      label: '客户抬头',
      prop: 'unit',
    },
    {
      label: '申请时间',
      prop: 'createTime',
    },
    {
      label: '售后原因',
      prop: 'reason',
    },
    {
      label: '更新时间',
      prop: 'lastUpdateTime',
    },
    {
      label: '操作',
      minWidth: '80px',
      render: (h, { row }) => {
        let actions = [h('span', {
          class: 'contract-option',
          on: {
            click: () => {
              this.operate('detail', row);
            },
          },
        }, '查看')];

        if (row.state === 6) { // 1-> 供应商待审核
          actions.push(h('span', {
            class: 'contract-option',
            on: {
              click: () => {
                this.operate('review', row);
              },
            },
          }, '审核'));
        }

        return actions;
      },
    },
  ];

  return serviceTableCols;
}
