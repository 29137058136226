export const executionStatusGroup = [
  {
    label: '全部售后',
    value: '',
    en: 'allAfterNum',
    count: 0,
  },
  {
    label: '待售后确认',
    value: '6',
    en: 'supAuditNum',
    count: 0,
  },
  {
    label: '售后处理中',
    value: '2',
    en: 'runningNum',
    count: 0,
  },
  {
    label: '已完成',
    value: '3',
    en: 'completeNum',
    count: 0,
  },
  {
    label: '售后驳回',
    value: '7',
    en: 'supRejectNum',
    count: 0,
  },
  {
    label: '已关闭',
    value: '5',
    en: 'closedNum',
    count: 0,
  },
];

export const refundStatusOptions = [
  {
    label: '待退款',
    value: 1,
  },
  {
    label: '部分退款',
    value: 2,
  },
  {
    label: '已退款',
    value: 3,
  },
];

export const returnStatusOptions = [
  {
    label: '待退货',
    value: 1,
  },
  {
    label: '部分退货',
    value: 2,
  },
  {
    label: '已退货',
    value: 3,
  },
];

export const typeStatusOptions = [
  {
    label: '仅退货',
    value: 1,
  },
  {
    label: '仅退款',
    value: 2,
  },
  {
    label: '退货退款',
    value: 3,
  },
];
