const filterRender = (val) => {
  if (!val && val !== 0) {
    return '--';
  }

  return val;
};

export function setServiceDetailTable () {
  const serviceDetailTableCols = [
    {
      title: '商品编码',
      field: 'goodsCode',
      formatter: ({ row }) => {
        return filterRender(row.goodsCode);
      },
    },
    {
      title: '商品名称',
      field: 'goodsName',
      formatter: ({ row }) => {
        return filterRender(row.goodsName);
      },
    },
    {
      title: '品牌名称',
      field: 'brandName',
      formatter: ({ row }) => {
        return filterRender(row.brandName);
      },
    },
    {
      title: '规格',
      field: 'specification',
      formatter: ({ row }) => {
        return filterRender(row.specification);
      },
    },
    {
      title: '型号',
      field: 'model',
      formatter: ({ row }) => {
        return filterRender(row.model);
      },
    },
    {
      title: '生产商',
      field: 'producer',
      formatter: ({ row }) => {
        return filterRender(row.producer);
      },
    },
    {
      title: '厂家编码',
      field: 'factoryCode',
      formatter: ({ row }) => {
        return filterRender(row.factoryCode);
      },
    },
    {
      title: '销售单价',
      field: 'unitPrice',
      formatter: ({ row }) => {
        return filterRender(row.unitPrice);
      },
    },
    {
      title: '是否医疗件',
      field: 'isMedichair',
      formatter: ({ row }) => {
        return row['isMedichair'] === 1 ? '是' : '否';
      },
    },
    {
      title: '注册证号',
      field: 'regNo',
      formatter: ({ row }) => {
        return filterRender(row.regNo);
      },
    },
    {
      title: '退货数量',
      field: 'currentReturnNum',
      slots: {
        default: ({row}, h) => {
          return [h('span', {
            class: 'del',
            on: {
              click: () => { this.handleReturn(row); },
            },
          }, row.currentReturnNum)];
        },
      },
    },
  ];

  return serviceDetailTableCols;
}
